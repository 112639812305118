import environment from "../environment"

const isTranslatePage = () => {
  let value = false
  let url = window.location.href
  if (url.includes('translation')) value = true
  return value
}

const generatekeysArr = (arr, key = 'typeofresult') => {
  let keys = {}
  if (!arr) return { keys, arr: [] }
  arr.map(itm => {
    if (keys[itm[key]]) {
      keys[itm[key]].push(itm)
    } else {
      keys[itm[key]] = [itm]
    }
  })
  return {
    keys, arr: Object.keys(keys).map(itm => {
      return { key: itm, value: keys[itm] }
    })
  }
}

const userImg = (img) => {
  let value = '/assets/img/placeholder.png'
  if (img?.includes('https')) {
    return img
  }
  if (img) value = environment.imageApi + img
  if (img && img.includes('http')) value = img
  return value
}

const noImg = (img, modal = 'img') => {
  let value = '/assets/img/placeholder.png'
  if (img?.includes('https')) {
    return img
  }
  if (img) value = environment.imageApi + img
  if (img?.includes('http')) value = img
  return value
}

const getPrams = (p) => {
  const params = new URLSearchParams(window.location.search)
  return params.get(p)
}


const isNumber = (e) => {
  let key = e.target;
  let maxlength = key.maxLength ? key.maxLength : 1;

  key.value = key.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
  let max = Number(key.max ? key.max : key.value);
  if (Number(key.value) > max) key.value = max;

  let min = key.min;
  if (min && Number(key.value) < Number(min)) key.value = min;
  if (key.value.length > maxlength) key.value = key.value.slice(0, maxlength);
  return key.value
}

const isRatio = (e) => {
  let key = e.target;
  let maxlength = key.maxLength ? key.maxLength : 1;

  // let max = Number(key.max ? key.max : key.value);
  // if (Number(key.value) > max) key.value = max;

  // let min = key.min;
  // if (min && Number(key.value)<Number(min)) key.value = min;

  if (key.value.length > maxlength) key.value = key.value.slice(0, maxlength);
  key.value = key.value.replace(/[^0-9.>]/g, '').replace(/(\..*?)\..*/g, '$1');

  return key.value
}

const find = (arr, value, key = 'key') => {
  let ext = arr?.find(itm => itm[key] == value)
  return ext
}


/* ###################### Form Methods #########################  */

// get Single field error
const getError = (key, fvalue, formValidation) => {
  let ext = find(formValidation, key)
  let res = matchError(ext, fvalue)
  return res
}

const emailRequiredFor = (role) => {
  let value = false
  if (role == 'Clinic Admin' || role == 'Counsellor' || role == 'Owner' || role == 'admin') value = true
  return value
}


const validateUsername = (val) => {
  return /^(?=[a-zA-Z0-9._-]{8,20}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(val);
}

const dialMatch = (val) => {
  let value = false
  value = val.match(/^(?=.*[0-9])(?=.*[+])[0-9+]{2,5}$/)
  return value
}
const emailvalidation = (val) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
    return true
  }
  return false
}
// match errors for fields
const matchError = (ext, fValue) => {

  let invalid = false
  let kValue = fValue[ext.key]
  let value = { minLength: false, maxLength: false, confirmMatch: false, required: false }
  if (ext.required) {
    if (!kValue || (!kValue.length && typeof kValue != 'object')) {
      invalid = true
    }
  }
  if (ext.minLength && kValue) {
    if (kValue.length < ext.minLength) value.minLength = true
  }
  if (ext.maxLength && kValue) {
    if (kValue.length > ext.maxLength) value.maxLength = true
  }
  if (ext.dialCode && kValue) {
    if (dialMatch(kValue)) {
      kValue.indexOf("+");
      if (kValue.indexOf("+") != 0) {
        value.dialCode = true
      }

    } else {
      value.dialCode = true
    }
  }

  if (ext.username && kValue) {
    if (!validateUsername(kValue)) value.username = true
  }

  if (ext.confirmMatch && kValue) {
    if (fValue[ext.confirmMatch[0]] != fValue[ext.confirmMatch[1]]) value.confirmMatch = true
  }

  let vArr = Object.keys(value)
  vArr.map(itm => {
    if (value[itm]) invalid = true
  })

  let res = { invalid: invalid, err: value }
  return res
}

// get form error (All Fields)
const getFormError = (formValidation, fvalue) => {
  let invalid = false
  formValidation.map(ext => {
    if (matchError(ext, fvalue).invalid) {

      invalid = true
    }
  })

  return invalid
}

/* ###################### Form Methods end #########################  */

const route = (route) => {
  localStorage.setItem('route', route)
  let el = document.getElementById('routerDiv')
  setTimeout(() => {
    if (el) el.click()
  }, 100)
}


const EmailValidator = (email) => {
  if (email?.includes('.')) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  } else {
    return false
  }

}


const URLvalidator = (url) => {
  let regex = /^(https?:\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/\S*)?$/;;
  if (regex.test(url)) {
    //  if (url.includes('https://') || url.includes('http://')){
    //     return true
    //  }else{
    return true
    //  }
  } else {
    return false
  }

}


const URLTextFinder = (text) => {

  let urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    // return '<a href="' + url + '">' + url + '</a>';
    function removeHtmlTags(html) {
      return html.replace(/<[^>]*>/g, '');
    }
    return `<a href='${removeHtmlTags(url)}' target='__blank'>${url} </a>`
  });
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

function colorfulAvatar(name) {
  if (typeof name !== 'string' || name.length === 0) {
    return ''; // return empty string if input is not valid
  }

  // Array of Tailwind CSS text colors
  const textColors = ['text-red-500', 'text-blue-500', 'text-green-500', 'text-yellow-500', 'text-purple-500'];

  // Array of Tailwind CSS background colors
  const bgColors = ['bg-red-200', 'bg-blue-200', 'bg-green-200', 'bg-yellow-200', 'bg-purple-200'];

  // Convert the first character of the name to uppercase
  const firstLetter = name.charAt(0).toUpperCase();

  // Calculate ASCII value of the first character
  const charCode = firstLetter.charCodeAt(0);

  // Use modulus operation to get indices for colors based on ASCII value
  const randomTextColorIndex = charCode % textColors.length;
  const randomBgColorIndex = charCode % bgColors.length;

  // Generate HTML with Tailwind CSS classes for styling
  const coloredAvatar = <div class=" flex items-center shrink-0 justify-center h-8 w-8 rounded-full bg-[#E9D5FF]">
    <div class="text-lg font-medium text-[#A855F7]">{firstLetter}</div>
  </div>;

  return coloredAvatar;
}



function MonthNameGetter(dt) {
  // Define an array containing names of months
  const mlist = ['', "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  // Return the name of the month corresponding to the month index of the provided date
  return mlist[dt];
};

const ConverterHtmlCode = (html = '') => {

  // Create a DOM element to parse the HTML string
  let parser = new DOMParser();
  let doc = parser.parseFromString(html, 'text/html');

  // Select all anchor tags and set their target attribute
  doc.querySelectorAll('a').forEach(anchor => {
    anchor.setAttribute('target', '_blank');

  });
  // Serialize the modified HTML back to a string
  let serializer = new XMLSerializer();
  let updatedHtmlString = serializer.serializeToString(doc);

  return updatedHtmlString
}
function capitalizeFirstLetter(name) {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}
function replaceCommasWithBreaks(str) {
  // Use a regular expression to replace all commas with <br/>
  return str.replace(/,/g, ', <br/>');
}

function validatePassword(password) {
  // Define regular expressions for validation
  const lengthRegex = /^.{8,16}$/; // Between 8 and 16 characters
  const lowercaseRegex = /[a-z]/;  // At least one lowercase letter
  const uppercaseRegex = /[A-Z]/;  // At least one uppercase letter
  const numberRegex = /[0-9]/;     // At least one number
  const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;"'<>,.?/~`|\\]/; // At least one special character
  // Check length
  if (!lengthRegex.test(password)) {
    return ({ success: false, message: "Password must be between 8 and 16 characters long." })
  }

  // Check for lowercase letter
  if (!lowercaseRegex.test(password)) {
    return ({ success: false, message: "Password must include at least one lowercase letter." })
  }

  // Check for uppercase letter
  if (!uppercaseRegex.test(password)) {
    return ({ success: false, message: "Password must include at least one uppercase letter." })
  }

  // Check for number
  if (!numberRegex.test(password)) {
    return ({ success: false, message: "Password must include at least one number." })
  }

  // Check for special character
  if (!specialCharRegex.test(password)) {
    return ({ success: false, message: "Password must include at least one special character." })
  }

  // If all checks pass
  return ({ success: true, message: "Password is valid." })
}

const methodModel = { validatePassword, replaceCommasWithBreaks, ConverterHtmlCode, userImg, colorfulAvatar, route, URLTextFinder, URLvalidator, isNumber, EmailValidator, isRatio, find, getError, getFormError, getPrams, emailRequiredFor, emailvalidation, noImg, isTranslatePage, generatekeysArr, MonthNameGetter, capitalizeFirstLetter }
export default methodModel