import React from "react";
import ReactDOM from "react-dom";
import Main from "./main";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import configureStore from "./config";
import { createRoot } from "react-dom/client";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import environment from "../src/environment/index";

export const history = createBrowserHistory();

const { persistor, store } = configureStore(history);

Sentry.init({
  dsn: environment.sentry_dsn,
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["/^https:\/\/ap\.dazhboards\.com/"],
});

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={"loading..."} persistor={persistor}>
      <Main />
      {/* </ConnectedRouter> */}
    </PersistGate>
  </Provider>
);
