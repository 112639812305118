import ApiClient from '../methods/api/apiClient'
import methodModel from '../methods/methods';
export const Logo =() =>async(dispatch)=>{
  await  ApiClient.get(`api/setting/site`,{},'','',{apiCall:true}).then(res=>{
        if(res.success){
            if(res.data?.fav_icon){

                document.getElementById('iconData').href=methodModel.userImg(res?.data?.fav_icon)
            }
            //  I AM PUTTING THE LOGO STATICALLY WHEN THE CODE IS LIVE REMOEV THE STATIC LOGO  JUST REMOVE THEN IT WORK SMOOTHLY ON ALL 
            return dispatch({ type: 'Logo', data:{...res.data,logo:res.data?.logo?methodModel.userImg(res?.data?.logo):'/assets/img/logo.webp'} })
        }
    })
};