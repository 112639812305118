export const ITEM = [
  {
    badgeLabel: "Total Sales",
    value: "$3,4598",
    increment: "2.11%",
  },
  {
    badgeLabel: "Total orders",
    value: "34K",
    increment: "2.11%",
  },
  {
    badgeLabel: "Total Pax",
    value: "345.8K",
    increment: "2.11%",
  },
  {
    badgeLabel: "Additional Revenue",
    value: "$4,598",
    decrement: "2.11%",
  },
  {
    badgeLabel: "Average Order value",
    value: "$67.00",
    decrement: "2.11%",
  },
  {
    badgeLabel: "Commission Paid",
    value: "$2,980",
    increment: "2.11%",
  },
  {
    badgeLabel: "Time",
    value: "56 Days",
    increment: "2.11%",
  },
];

export const ITEM_1 = [
  {
    badgeLabel: "Total Sales",
    key: 'totalSale',
  },
  {
    badgeLabel: "Discounted Offered",
    key: '',
  },
  {
    badgeLabel: "Commissions Paid",
    key: 'commissionPaid',
  },
  {
    badgeLabel: "Net Sales",
    key: 'totalSale',
  },
  {
    badgeLabel: "Forward Bookings",
    key: 'forward_bookings'
  }
];

export const ITEM_2 = [
  {
    badgeLabel: "Reseller Sales",
    key: 'totalSale',
  },
  {
    badgeLabel: "Commissions Paid",
    key: 'commissionPaid',
  },
  {
    badgeLabel: "Net Sales from Resellers",
    key: 'totalSale',
  },
  {
    badgeLabel: "Forward Bookings",
    key: 'forward_bookings'
  }
];

export const ITEM_3 = [
  {
    badgeLabel: "Invoice Raised",
    key: 'invoice_raised',
  },
  {
    badgeLabel: "Invoice Paid",
    key: 'invoice_raised',
  },
  {
    badgeLabel: "Balance Owing",
    key: 'invoice_due',
  },
  {
    badgeLabel: "Forward Bookings",
    key: 'forward_bookings',
  }
];


export const ITEM_4 = [

  {
    badgeLabel: "Sales",
    key: 'sales',
    type: 'currency'
  },
  {
    badgeLabel: "Bookings",
    key: 'bookings',
    type: 'num'
  },
  {
    badgeLabel: "AOV",
    key: 'avg_order_value',
    type: 'num'
  },
  {
    badgeLabel: "Commissions Paid",
    key: 'commissions',
    type: 'currency'
  },
  {
    badgeLabel: "Ops Costs",
    key: 'cost',
    type: 'currency'
  },
  {
    badgeLabel: "Total Costs",
    key: 'total_costs',
    type: 'currency'
  },
  {
    badgeLabel: "Profit/Loss",
    key: 'profit',
    type: 'mix'
  },
  {
    badgeLabel: "% of tours - Profit",
    key: 'profit_per',
    type: 'per'
  },
  {
    badgeLabel: "% of tours - Breakeven",
    key: 'breakeven_per',
    type: 'per'
  },
  {
    badgeLabel: "% of tours - Loss",
    key: 'loss_per',
    type: 'per'
  },
  {
    badgeLabel: "Cost of Acquisition (Agent) ",
    key: 'indirect_orders_per',
    type: 'mix'
  },
  {
    badgeLabel: "Cost of Acquisition (Direct)",
    key: 'cost_of_acquisition_direct',
    type: 'per'
  },
  {
    badgeLabel: "Cost of Acquisition - Affiliate ",
    key: 'cost_of_acquisition_affiliate',
    type: 'per'
  },
  {
    badgeLabel: "Direct v/s Agent Bookings (%)",
    key: 'direct_orders_per',
    type: 'per'
  },
];

export const CHART_OPTIONS = [
  { option: "Bar Chart" },
  { option: "Pie Chart" },
  { option: "Line Chart" },
];

export const MONTH_OPTIONS = [
  { option: "This Month" },
  { option: "Last Month" },
];

export const colourStyles = {
  menuList: (styles) => ({
    ...styles,
    "::-webkit-scrollbar": {
      width: "6px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#ddd",
    },
    fontSize: 14,
  }),
  control: (styles, state) => ({
    ...styles,
    cursor: "pointer",
    borderWidth: 0,
    minHeight: 40,
    backgroundColor: "white",
    borderRadius: 8,
    textAlign: "left",
    minWidth: 180,
    boxShadow:
      "0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24)",
    outline: state.isFocused ? "2px solid #481196" : "",
    fontSize: 14,
    paddingLeft: 4,
    paddingRight: 4,
  }),
  menu: (styles) => ({
    ...styles,
    outline: "2px solid #481196",

  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? "#481196"
          : isFocused
            ? "#481196"
            : undefined,
      color: isFocused ? "#fff" : isDisabled ? "#ccc" : isSelected ? "#fff" : "#000",
      fontWeight: isSelected ? 500 : 400,
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#000"
            : "#F7FAFF"
          : undefined,
        color: !isDisabled ? isSelected && "#000" : undefined,
      },
    };
  },
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#75757A"
  }),
  input: (styles) => ({
    ...styles,
    fontSize: 14,
    color: "#1D2433",
    fontWeight: 400,
  }),
  placeholder: (styles) => ({ ...styles, fontWeight: 400, fontSize: 14, color: "#1D2433" }),
  singleValue: (styles) => ({ ...styles }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  })
};
import React from 'react'
const MySelect = (props) => {
  return (
    <MySelect
      {...props}
      styles={colourStyles}
      components={{ DropdownIndicator }}
    />
  );
};

export const userTabs = [
  { title: 'User Roles', link: '/roles' },
  { title: 'User List', link: '/users' },
];

export const BookingTabs = [
  { title: 'Booking Systems', link: '/pos' },
  { title: 'Reviews', link: '/api/review-connected?show=false' },
  { title: 'Omni Channel ', link: '/omni-channel-setting/copy' },
  { title: 'Marketing', link: '/marketing-analytics' },
  { title: 'Accounting', link: '/api/accounting' },
  // { title: 'Communication Channel Widget', link: '/omni-channel-setting/widget' },
  // { title: 'Social Media Integration', link: '/omni-channel-setting/social/media' },
  // { title: 'Google Tag Manager', link: '/omni-channel-setting/google/tag' },
];


export const XeroTabs = [
  { title: 'Xero Invoices', link: '/accounting/invoices' },
  { title: 'Bills to Pay', link: '/accounting/bills' },
];
export const SidebarItems = [
  { name: 'Dashboard', Link: '/dashboard' },
  { name: 'Chatbot', Link: '/chatbot' },
  { name: 'Chat', Link: '/omni-channels/live-chat' },
  { name: 'WhatsApp Template', Link: '/whatsapp/template' },
  { name: 'Email Template', Link: '/gmail/template' },
  { name: 'Chat Widget', Link: '/omni-channel-setting/widget' },
  { name: 'Sales Insight', Link: '/sales' },
  { name: 'Sales Data', Link: '/sales-data' },
  { name: 'Historial Sales', Link: '' },
  { name: 'Sales Refund Data', Link: '/sales/refunds' },
  { name: 'Product Insights', Link: '/product-dashboard' },
  { name: 'Product Data', Link: '/product-data' },
  { name: 'Deleted Products', Link: '/delete/product-data/' },
  { name: 'Cancellation Policies', Link: '/cancel/policy' },
  { name: 'Historical Product', Link: '' },
  { name: 'Budget Per Tour', Link: '/costing/template' },
  { name: 'Costing Per Tour', Link: '/costingtour/template' },
  { name: 'Update Logs', Link: '/reseller/updates' },
  { name: 'Update Logs Setting', Link: '/content/settings' },
  { name: 'Manage Rules', Link: '/dynamicprice-list' },
  { name: 'Customer Insights', Link: '/customers' },
  { name: 'Customer Data', Link: '/customer-data' },
  { name: 'Review Insights', Link: '/review-dashboard' },
  { name: 'Review Management', Link: '/reviews' },
  { name: 'Review Settings', Link: '/reviews/settings' },
  { name: 'Reseller Insights', Link: '/reseller-dashboard' },
  { name: 'List of Resellers', Link: '/reseller-data' },
  { name: 'Reseller Invoices', Link: '/reseller-invoices' },
  { name: 'Contract Templates', Link: '/crm' },
  { name: 'Profitability Insights', Link: '/accounting/dashboard' },
  { name: 'Profitability Data', Link: '/accounting/transactions' },
  { name: 'Company Details', Link: '/company' },
  { name: 'Partners', Link: '/partner' },
  { name: 'Email List', Link: '/email/list' },
  { name: 'Email Templates', Link: '/email/templates' },
  { name: 'Data Connection', Link: '/pos' },
  { name: 'Users', Link: '/users' },
  { name: 'Plans', Link: '/activeplan' },
  { name: 'Billings', Link: '/card' },
  { name: 'Waiver Templates', Link: '/waiver/list' },
  { name: 'Waiver Settings', Link: '/wavier/settings' },
  { name: 'Google Marketing', Link: '/marketing/google-analytics' },
  { name: 'Google Tag Manager', Link: '/google/tags' },
  { name: 'Social Media', Link: '/social/media' },
  { name: 'Affiliate Database', Link: '/affiliate' },
  { name: 'Current Affiliate', Link: '/current-affiliate' },
  { name: 'Affiliate Request', Link: '/request' },
  { name: 'General Banners', Link: '/general/banners' },
  { name: 'Products Banners', Link: '/products/banners' },
  { name: 'Default Commission', Link: '/default-commission' },
  { name: 'Affiliate Requeset', Link: '' }, { name: 'Inbox', Link: '/affiliateInbox' },
  { name: 'Transactions', Link: '/transactions' },
  { name: 'Sales', Link: '/report/sales' },
  { name: 'Affiliates', Link: '/report/affiliates' },
  { name: 'Affiliate Customers', Link: '/report/customers' },
]



