import React, { Suspense, useEffect, lazy, useState } from "react";
import {
  ToastContainer,
} from "react-toastify";
import { PersistGate } from "redux-persist/es/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import configureStore from "./config";

import "react-pagination-js/dist/styles.css";
// import "./scss/main.scss";
import "react-toastify/dist/ReactToastify.css";
// progressbar;
import "react-circular-progressbar/dist/styles.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-phone-input-2/lib/style.css";
import { Auth } from "./methods/auth";
const SalesDataRetrive = lazy(() => import('./pages/SalesDashboard/SalesRetriveData'));
const TwoFactorAuth = lazy(() => import('./pages/TwoFactorAuth'));
const FacebookConversation = lazy(() => import('./pages/OmniChannel/FacebookConversation'));
const OmniChannel = lazy(() => import('./pages/OmniChannel'));
const OmniChannelWidget = lazy(() => import('./pages/OmniChannel/Widget'));
const FacebookChat = lazy(() => import('./pages/OmniChannel/FacebookChat'));
const LiveChat = lazy(() => import('./pages/OmniChannel/LiveChat'));
const WhatsappChat = lazy(() => import('./pages/OmniChannel/WhatsappChat'));
const WhatsappIntegration = lazy(() => import('./pages/OmniChannel/WhatsappIntegration'));

const OmniChat = lazy(() => import('./pages/OmniChannel/Chat'));
const OmniIntegrations = lazy(() => import('./pages/OmniChannel/Integrations'));
const RefundData = lazy(() => import('./pages/SalesDashboard/RefundData'));
const GmailIntegration = lazy(() => import('./pages/OmniChannel/GmailIntegration'));
const Partner = lazy(() => import('./pages/PartnerModule/Partner'));
const AddEditCoupon = lazy(() => import('./pages/CouponsModule/AddEditCoupon'));
const CouponDetail = lazy(() => import('./pages/CouponsModule/ViewCoupons'));
const CouponDashboard = lazy(() => import('./pages/CouponsModule/CouponDashboard'));
const ViewWavier = lazy(() => import('./pages/Wavier/View'));
const CWavierTemplateProducts = lazy(() => import('./pages/Wavier/WaiverTemplateProducts'));
const WavierList = lazy(() => import('./pages/Wavier'));

const AddEditWavierTemp = lazy(() => import('./pages/Wavier/AddEdit'));
const Preparation = lazy(() => import('./pages/PDFSIGN/Prepration'));
const SignDocument = lazy(() => import('./pages/PDFSIGN/SIGNATURE'));
const AddEditWhatsAppTemplate = lazy(() => import('./pages/WhatsappTemplates/AddEditWhatsAppTemplate'));
const WhatsappTemplate = lazy(() => import('./pages/WhatsappTemplates/WhatsappTemplate'));
const WaiverSignDetails = lazy(() => import('./pages/Wavier/WaiverSignDetails'));
const SubmissionTanks = lazy(() => import('./pages/SubmissinTahnku/SubmissionTanks'));
/************ store configration *********/

const CouponList = React.lazy(() => import("./pages/CouponsModule/CouponList"));
const Login = React.lazy(() => import("./pages/Login"));
const SignUp = React.lazy(() => import("./pages/Signup"));
const Forgotpassword = React.lazy(() => import("./pages/Forgotpassword"));
const Resetpassword = React.lazy(() => import("./pages/Resetpassword"));
const Dashboard = React.lazy(() => import("./pages/DashboardPages"));
const Profile = React.lazy(() => import("./pages/Profile"));
const EditProfile = React.lazy(() => import("./components/Profile/Edit"));
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();
const ReteriveProductData = React.lazy(() =>
  import("./pages/ProductDashboard/RetriveProduct")
);
const ChangePassword = React.lazy(() =>
  import("./components/Profile/ChangePassword")
);
const Plans = React.lazy(() => import("./pages/Plan"));
const Cards = React.lazy(() => import("./pages/Cards"));
const AddEditCards = React.lazy(() => import("./pages/Cards/AddEdit"));
const CardsDetail = React.lazy(() => import("./pages/CardsDetail"));
const ProductDetail = React.lazy(() =>
  import("./pages/Products/Detail/ProductDetail")
);
const ProductDetail1 = React.lazy(() =>
  import("./pages/Products/Detail1/ProductDetail")
);
const POS = React.lazy(() => import("./pages/POS"));
const MarketingSoftware = React.lazy(() =>
  import("./pages/POS/MarketingSoftware")
);
const AddEditPOS = lazy(() => import('./pages/POS/AddEdit'));
const NoPages = lazy(() => import('./pages/404'));
const EmailList = lazy(() => import('./pages/EmailLists/EmailList'));
const AddEditEmailTemplate = lazy(() => import('./pages/EmailLists/AddEdit'));
const EmailTemplate = lazy(() => import('./pages/EmailLists/EmailTemplate'));
const SocialMediaPost = lazy(() => import('./pages/MediaScheduling'));
const PostForm = lazy(() => import('./pages/MediaScheduling/AddEditPostSchedule'));
const IntegrateScheduling = lazy(() => import('./pages/MediaScheduling/IntegrateScheduling'));
const XeroBills = lazy(() => import('./pages/Xero/BIllsToPay'));
const XeroBillDetail = lazy(() => import('./pages/Xero/BIllsToPay/Detail'));
const CancelReason = lazy(() => import('./pages/CancelReasons'));
const AddEditPolicy = lazy(() => import('./pages/CancelReasons/AddEditCancel'));
const ContentSettings = lazy(() => import('./pages/ContentsResellers/ContentSettings'));
const ResellerContent = lazy(() => import('./pages/ContentsResellers/ResellerContent'));
const ContentDetails = lazy(() => import('./pages/ContentsResellers/ContentDetails'));
const GoogleTagManager = lazy(() => import('./pages/GoogleTagManager'));
const GoogleTagManagers = lazy(() => import('./pages/GoogleTagManager/AddEditGoogleTagManager/GoogleTagManager'));
const AddEditGoogleTag = lazy(() => import('./pages/GoogleTagManager/AddEditGoogleTagManager/AddEditGoogleTag'));
const SupplierInvoices = lazy(() => import('./pages/SupplierInvoices'));
const SupplierInvoiceDetail = lazy(() => import('./pages/SupplierInvoices/InvoiceDetail'));
const InvoiceDetailPage = lazy(() => import('./pages/SupplierInvoices/InvoicePage'));
const CostingTemplates = lazy(() => import('./pages/CostingTemplate'));
const AddEditCostingTemplate = lazy(() => import('./pages/CostingTemplate/AddEditCostingTemplate'));
const AddEditCostingTemp = lazy(() => import('./pages/CostingTemplate/AddEdit'));

import { GmailTempURL } from "./pages/GmailTemplate/Api";
const GmailTemplates = lazy(() => import('./pages/GmailTemplate'));
const AddEditGmailTemp = lazy(() => import('./pages/GmailTemplate/AddEdit'));
const ReviewSettings = lazy(() => import('./pages/ReviewsSettings'));
import { Logo } from "./actions/Logo";
import { CostingTourURL } from "./pages/CostPerTourTemplate/Api";
const AllContract = lazy(() => import('./pages/CRM/AllContract'));
const CostingTourTemplates = lazy(() => import('./pages/CostPerTourTemplate'));
const AddEditCostingTourTemp = lazy(() => import('./pages/CostPerTourTemplate/AddEdit'));
const RequestData = lazy(() => import('./pages/AffiliateRequest/RequestData'));
const AffiliateData = lazy(() => import('./pages/Affiliates/Affiliate'));
const Banners = lazy(() => import('./pages/Banner'));
import { BannerURL } from "./pages/Banner/Api";
const AddEditBanner = lazy(() => import('./pages/Banner/AddEdit'));
const AffiliateCommission = lazy(() => import('./pages/Affiliates/AffiliateCommission'));
const CommissionDetails = lazy(() => import('./pages/DefaultCommission'));
const Terms = lazy(() => import('./pages/Terms'));
const DeleteProductData = lazy(() => import('./pages/ProductDashboard/DeleteProductData'));
const InboxMessages = lazy(() => import('./pages/InboxSection/AffiliateMessages'));
const InboxDetail = lazy(() => import('./pages/InboxSection'));

import { WaiverSettings } from "./pages/Wavier/WaiverSettings";
const CopyofIntegration = lazy(() => import('./pages/OmniChannel/CopyofIntegration'));
const CurrentAffiliateData = lazy(() => import('./pages/Affiliates/CurrentAffiliate'));
const Transactions = lazy(() => import('./pages/Reports/Transactions'));
const Sales = lazy(() => import('./pages/Reports/Sales'));
const AffiliatesReport = lazy(() => import('./pages/Reports/AffiliatesReport'));
const AffiliateCustomers = lazy(() => import('./pages/Reports/Customers'));
const ProductBanners = lazy(() => import('./pages/ProductBanners'));
const AddEditProductBanner = lazy(() => import('./pages/ProductBanners/AddEdit'));
import { productBannerURL } from "./pages/ProductBanners/Api";

import { rule_base } from "./actions/rules";
import noPages from "./pages/404";
import ApiClient from "./methods/api/apiClient";
import socket from "./models/socket.model";
import QuickBookConnect from "./pages/Xero/QuickBookConnect";
import DynamicPicingProcharter from "./pages/Products/Detail1/DynamicPicingProcharter";
const AllInvoices = React.lazy(() => import('./pages/InvoiceTemplates/AllInvoices'));
const AddEditInvoiceTemplate = React.lazy(() => import('./pages/InvoiceTemplates/AddEditInvoiceTemplate'));
const ViewInvoiceTemplate = React.lazy(() => import('./pages/InvoiceTemplates/View'));
const InvoiceTemplateProducts = React.lazy(() => import('./pages/InvoiceTemplates/InvoiceTemplateProducts'));
const Invoices = React.lazy(() => import('./pages/InvoiceTemplates'));
const AddEditInvoice = React.lazy(() => import('./pages/InvoiceTemplates/AddEdit'));
const InvoiceViewTemp = React.lazy(() => import('./pages/SalesDashboard/InvoiceViewTemp'));
const WaiverDatabase = React.lazy(() => import('./pages/waiverDatabase/WaiverDatabase'));
const WaiverDataDetail = React.lazy(() => import('./pages/waiverDatabase/WaiverDataDetail'));
const SupplierCommissionDetails = React.lazy(() => import('./pages/SupplierInvoiceCommission/SupplierInvoiceSetting'));


const Orders = React.lazy(() => import("./pages/POS/Orders/Orders"));
const CompanyDetails = React.lazy(() => import("./pages/CompanyDetails"));
const BookingSystem = React.lazy(() => import("./pages/BookingSystem"));
const AddEditBooking = React.lazy(() =>
  import("./pages/BookingSystem/AddEdit")
);
const Salesdashboard = React.lazy(() => import("./pages/SalesDashboard"));
const Resellerdashboard = React.lazy(() =>
  import("./pages/SalesDashboard/ResellerDashboard")
);
const Marketingdashboard = React.lazy(() =>
  import("./pages/MarketingDashboard")
);
const Financialdashboard = React.lazy(() =>
  import("./pages/FinancialDashboard")
);
// import Resellerdashboard from React.lazy(() => import('./pages/ResellerDashboard'));
const ActivePlan = React.lazy(() => import("./pages/Plan/ActivePlan"));
const Payment = React.lazy(() => import("./pages/Payment"));
const OrderDetail = React.lazy(() => import("./pages/POS/Orders/OrderDetail"));
const Calendar = React.lazy(() => import("./pages/Calendar"));
const SalesData = React.lazy(() => import("./pages/SalesDashboard/salesData"));
const Productdashboard = React.lazy(() => import("./pages/ProductDashboard"));
const ProductData = React.lazy(() =>
  import("./pages/ProductDashboard/ProductData")
);
const AddEditPrice = React.lazy(() =>
  import("./pages/DynamicPrice/AddEditPage")
);
const DynamicPrice = React.lazy(() => import("./pages/DynamicPrice"));

const CustomerData = React.lazy(() => import("./pages/Customers/CustomerData"));
const Customers = React.lazy(() => import("./pages/Customers"));

const ResellerDatabase = React.lazy(() => import("./pages/ResellerDatabase"));
const ApplyProduct = React.lazy(() =>
  import("./pages/ProductDashboard/ApplyProduct")
);
const AddEditEarlyBirdPricing = React.lazy(() =>
  import("./pages/DynamicPrice/Early/AddEditPage")
);
const AddEditEarlyBirdTimeFrames = React.lazy(() =>
  import("./pages/DynamicPrice/TimeFrames/AddEditPage")
);
const AddEditEarlyBirdInventory = React.lazy(() =>
  import("./pages/DynamicPrice/Inventory/AddEditPage")
);
const Home = React.lazy(() => import("./pages/Home"));
const Roles = React.lazy(() => import("./pages/Roles"));
const AddEditRole = React.lazy(() => import("./pages/Roles/AddEdit"));
const AddEditLastMinuteFixedDates = React.lazy(() =>
  import("./pages/DynamicPrice/LastMinuteFixedDates/AddEditPage")
);
const AddEditLastMinuteInventory = React.lazy(() =>
  import("./pages/DynamicPrice/LastMinuteInventory/AddEditPage")
);
const Users = React.lazy(() => import("./pages/Users"));
const AddEditUser = React.lazy(() => import("./pages/Users/AddEditUser"));
const UserDetail = React.lazy(() => import("./pages/Users/Profiledetail"));
const Reviews = React.lazy(() => import("./pages/Reviews"));
const Googlereview = React.lazy(() => import("./pages/Reviews/Googlereview"));
const FacebookReviews = React.lazy(() =>
  import("./pages/Reviews/FacebookReviews")
);
const Googlereviewsteps = React.lazy(() =>
  import("./pages/Reviews/Googlereviewsteps")
);
const step2 = React.lazy(() => import("./pages/Reviews/step2"));
const sitereview = React.lazy(() => import("./pages/Reviews/Reviewsite"));
const POSReviews = React.lazy(() => import("./pages/POS/Reviews"));
const improveContact = React.lazy(() =>
  import("./pages/Reviews/ImproveContacts")
);
const CRM = React.lazy(() => import("./pages/CRM"));
const AddEditCRM = React.lazy(() => import("./pages/CRM/AddEdit"));
const ViewReseller = React.lazy(() => import("./pages/ResellerDatabase/view"));
const SendContract = React.lazy(() =>
  import("./pages/ResellerDatabase/SendContract")
);
const AddEditReseller = React.lazy(() =>
  import("./pages/ResellerDatabase/AddEditReseller")
);
const Waiver = React.lazy(() => import("./pages/Waiver"));
const AddEditWaiver = React.lazy(() => import("./pages/Waiver/AddEdit"));
const Thanku = React.lazy(() => import("./pages/Thanku"));
const ReviewDashboard = React.lazy(() =>
  import("./pages/Reviews/ReviewDashboard")
);

const BuyExtraProdcts = React.lazy(() =>
  import("./pages/ProductDashboard/BuyExtraProducts")
);
const ReviewPage = React.lazy(() => import("./pages/Reviews/ReviewPage"));
const ReviewDetail = React.lazy(() => import("./pages/Reviews/ReviewDetail"));
const ViewCRM = React.lazy(() => import("./pages/CRM/View"));

const All_review = React.lazy(() => import("./pages/reviewsAll"));
const MarketingAnalytics = React.lazy(() =>
  import("./pages/MarketingDashboard/MarketingAnalytics")
);
const ContractTemplateProducts = React.lazy(() =>
  import("./pages/CRM/ContractTemplateProducts")
);
const XeroConnect = React.lazy(() => import("./pages/Xero/XeroConnect"));
const XeroInvoices = React.lazy(() => import("./pages/Xero/Invoices"));
const XeroTransactions = React.lazy(() => import("./pages/Xero/transactions"));
const XeroInvoiceDetail = React.lazy(() =>
  import("./pages/Xero/Invoices/Detail")
);
const XeroTransactionDetail = React.lazy(() =>
  import("./pages/Xero/transactions/Detail")
);
const OTPpage = React.lazy(() => import("./pages/Signup/OTPpage"));

const CustomerDataDetails = React.lazy(() =>
  import("./pages/CustomersDazboard/Customers/CustomerDetails")
);
const ChatBot = React.lazy(() => import("./pages/ChatBot"));

const GoogleDriveCallBack = React.lazy(() =>
  import("./pages/ChatBot/Tabs/SourcesTab/googleDriveCallBack")
);
// Remove useNavigate and its dependencies
const { persistor, store } = configureStore(history);

export default () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state);
  localStorage.removeItem("joinedRoom");
  localStorage.removeItem("joinedPlatform");

  const user = useSelector((state) => state.user);
  // const Navigate = useNavigate();

  const RuleBasedData = () => {
    if (localStorage.getItem('token')) {
      ApiClient.get(`api/users/sidebar/data-count`, {}, '', '', { apiCall: true }).then(res => {
        if (res.success) {
          dispatch(rule_base(res.data))
        }
      })
    }
  }

  useEffect(() => {
    RuleBasedData()
  }, [])
  useEffect(() => {
    dispatch(Logo("Logo"));
  }, []);
  const [show, setShow] = useState(false);
  const [NotificationMessage, setNotificationMessage] = useState('');
  const [RouteData, setRouteData] = useState('')
  useEffect(() => {
    socket.on(`receive-message`, (data2) => {
      let data = data2.data;
      let id = user?.id || user?._id;
      if (data?.receiver == id) {
        try {
          document.getElementById("audioFile").play();
        } catch (err) {
          return null;
        }
        let Route = '';
        if (!window?.location.pathname.includes('/omni-channels/live-chat')) {
          Route = `/omni-channels/live-chat`
        } else {
          Route = `/omni-channels/live-chat?message=${data?.content}&reciever=${data?.receiver}`
        }
        setRouteData(Route)
        setNotificationMessage(`You have a new Message : ${data?.content}`)
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setNotificationMessage('')
          setRouteData('');
        }, 3000); // Hide after 3 seconds

      }
    });
  }, []);


  useEffect(() => {
    socket.on(`notify-message`, (data2) => {
      let data = data2?.data;
      let id = user?.id || user?._id;
      if (data?.user_id == id) {
        try {
          document.getElementById("audioFile").play();
        } catch (err) {
          return null;
        }
        try {
          let Route = '';
          Route = `/omni-channels/live-chat?tab=${data?.platform?.toLowerCase()}`;
          setRouteData(Route)
          setNotificationMessage(`Message from ${data?.platform || 'Facebook'} 💬: ${data?.message}`)

          setShow(true);
          setTimeout(() => {
            setShow(false);
            setNotificationMessage('')
            setRouteData('');
          }, 3000); // Hide after 3 seconds
        } catch {
          return null
        }
      }
    });

  }, []);


  return (
    <>
      <Suspense
        fallback={
          <div>
            <div id="loader" className="loaderDiv">
              <div>
                <img
                  src="/assets/img/loader.gif"
                  alt="logo"
                  className="pageLoader"
                  width="auto"
                  height="auto"
                />
              </div>
            </div>
          </div>
        }
      >
        <Router locationProp={createBrowserHistory()}>
          <Routes>
            <Route
              exact={true}
              store={store}
              path="/login"
              element={<Login />}
            />
            <Route
              exact={true}
              store={store}
              path="/terms"
              element={<Terms />}
            />
            <Route
              exact={true}
              store={store}
              path="/reseller-invoices"
              element={<SupplierInvoices />}
            />
            <Route
              exact={true}
              store={store}
              path="/supplier-invoice-detail"
              element={<SupplierInvoiceDetail />}
            />
            <Route
              exact={true}
              store={store}
              path="/waidatabase"
              element={<WaiverDatabase />}
            />
            <Route
              exact={true}
              store={store}
              path="/waidatabase/details/:id?"
              element={<WaiverDataDetail />}
            />

            <Route
              exact={true}
              store={store}
              path="/reseller/updates"
              element={<ResellerContent />}
            />

            <Route
              exact={true}
              store={store}
              path="/sales/refunds"
              element={<RefundData />}
            />
            <Route
              exact={true}
              store={store}
              path="/omni-channels/facebook-chat"
              element={<FacebookChat />}
            />
            <Route
              exact={true}
              store={store}
              path="/omni-channels/live-chat"
              element={<LiveChat />}
            />
            <Route
              exact={true}
              store={store}
              path="/omni-channels/chat"
              element={<OmniChat />}
            />
            <Route
              exact={true}
              store={store}
              path="/omni-channels/whatsapp-chat"
              element={<WhatsappChat />}
            />

            <Route
              exact={true}
              store={store}
              path="/omni-channel-setting"
              element={<OmniChannel />}
            />

            <Route
              exact={true}
              store={store}
              path="/content/settings"
              element={<ContentSettings />}
            />
            <Route
              exact={true}
              store={store}
              path="/coupons-data"
              element={<CouponList />}
            />
            <Route
              exact={true}
              store={store}
              path="/coupons"
              element={<CouponDashboard />}
            />
            <Route
              exact={true}
              store={store}
              path="/accounting/bills"
              element={<XeroBills />}
            />
            <Route
              exact={true}
              store={store}
              path="/coupons/add/:id?"
              element={<AddEditCoupon />}
            />
            <Route
              exact={true}
              store={store}
              path="/omni-channel-setting/integration"
              element={<OmniIntegrations />}
            />

            <Route
              exact={true}
              store={store}
              path="/omni-channel-setting/social/media"
              element={<IntegrateScheduling />}
            />

            <Route
              exact={true}
              store={store}
              path="/accounting/bill/:id"
              element={<XeroBillDetail />}
            />

            <Route
              exact={true}
              store={store}
              path="/omni-channel-setting/facebook"
              element={<FacebookConversation />}
            />
            <Route
              exact={true}
              store={store}
              path="/partner"
              element={<Partner />}
            />
            <Route
              exact={true}
              store={store}
              path="/omni-channel-setting/whatsapp"
              element={<WhatsappIntegration />}
            />

            <Route
              exact={true}
              store={store}
              path="/omni-channel-setting/gmail"
              element={<GmailIntegration />}
            />

            <Route
              exact={true}
              store={store}
              path="/omni-channel-setting/widget"
              element={<OmniChannelWidget />}
            />

            <Route
              exact={true}
              store={store}
              path="/two-factor-auth"
              element={<TwoFactorAuth />}
            />
            <Route
              exact={true}
              store={store}
              path="/otppage"
              element={<OTPpage />}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/dashboard"
              element={<Dashboard />}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/dashboard/orders"
              element={<Orders />}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/orders/:id"
              element={<OrderDetail />}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/profile"
              element={<Profile />}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/profile/edit"
              element={<EditProfile />}
            />
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/profile/change-password"
              element={<ChangePassword />}
            />
            <Route
              exact={true}
              store={store}
              path="/calendar"
              element={<Calendar />}
            />
            <Route
              exact={true}
              store={store}
              path="/activeplan"
              element={<ActivePlan />}
            />
            <Route
              exact={true}
              store={store}
              path="/product/detail1/:id"
              element={<ProductDetail />}
            />
            <Route
              exact={true}
              store={store}
              path="/product/:id"
              element={<ProductDetail1 />}
            />
            <Route
              exact={true}
              store={store}
              path="/delete/product/:id"
              element={<ProductDetail1 />}
            />
            <Route
              exact={true}
              store={store}
              path="/company"
              element={<CompanyDetails />}
            />
            <Route
              exact={true}
              store={store}
              path="/whatsapp/template"
              element={<WhatsappTemplate />}
            />
            <Route
              exact={true}
              store={store}
              path="/whatsapp/template/add/:id?"
              element={<AddEditWhatsAppTemplate />}
            />
            <Route
              exact={true}
              store={store}
              path="/signup"
              element={<SignUp />}
            />
            <Route
              exact={true}
              store={store}
              path="/forgotpassword"
              element={<Forgotpassword />}
            />
            <Route
              exact={true}
              store={store}
              path="/prepare"
              element={<Preparation />}
            />

            <Route
              exact={true}
              store={store}
              path="/signdoc"
              element={<SignDocument />}
            />
            <Route
              exact={true}
              store={store}
              path="/resetpassword"
              element={<Resetpassword />}
            />

            <Route
              exact={true}
              store={store}
              path="/sales-data"
              element={<SalesData />}
            />
            {/*  Sales Retrivable Data */}
            <Route
              exact={true}
              store={store}
              path="/sales/retrievable"
              element={<SalesDataRetrive />}
            />

            <Route
              exact={true}
              path="/users"
              store={store}
              requireAuth={Auth}
              element={<Users />}
            />
            <Route
              exact={true}
              path="/users/add"
              store={store}
              requireAuth={Auth}
              element={<AddEditUser />}
            />
            <Route
              exact={true}
              path="/users/edit/:id"
              store={store}
              requireAuth={Auth}
              element={<AddEditUser />}
            />

            <Route
              exact={true}
              store={store}
              path="/roles"
              element={<Roles />}
            />
            <Route
              exact={true}
              store={store}
              path="/roles/add"
              element={<AddEditRole />}
            />
            <Route
              exact={true}
              store={store}
              path="/roles/edit/:id"
              element={<AddEditRole />}
            />

            <Route
              exact={true}
              path="/product-dashboard"
              store={store}
              requireAuth={Auth}
              element={<Productdashboard />}
            />
            <Route
              exact={true}
              path="/product-data/:type?"
              store={store}
              requireAuth={Auth}
              element={<ProductData />}
            />
            <Route
              exact={true}
              path="/products/retrivable"
              store={store}
              requireAuth={Auth}
              element={<ReteriveProductData />}
            />

            <Route
              exact={true}
              path="/delete/product-data/"
              store={store}
              requireAuth={Auth}
              element={<DeleteProductData />}
            />


            <Route
              exact={true}
              path="/customer-data"
              store={store}
              requireAuth={Auth}
              element={<CustomerData />}
            />
            <Route
              exact={true}
              path="/request"
              store={store}
              requireAuth={Auth}
              element={<RequestData />}
            />
            <Route
              exact={true}
              path="/affiliate"
              store={store}
              requireAuth={Auth}
              element={<AffiliateData />}
            />
            <Route
              exact={true}
              path="/customers"
              store={store}
              requireAuth={Auth}
              element={<Customers />}
            />
            <Route
              exact={true}
              path="/reseller-data"
              store={store}
              requireAuth={Auth}
              element={<ResellerDatabase />}
            />
            <Route
              exact={true}
              path="/reseller-data/add"
              store={store}
              requireAuth={Auth}
              element={<AddEditReseller />}
            />
            <Route
              exact={true}
              path="/reseller-data/edit/:id"
              store={store}
              requireAuth={Auth}
              element={<AddEditReseller />}
            />
            <Route
              exact={true}
              path="/google/tag/add/:id?"
              store={store}
              requireAuth={Auth}
              element={<AddEditGoogleTag />}
            />

            <Route
              exact={true}
              path="/reseller-data/view/:id"
              store={store}
              requireAuth={Auth}
              element={<ViewReseller />}
            />

            <Route
              exact={true}
              path="/reviews"
              store={store}
              requireAuth={Auth}
              element={<Reviews />}
            />
            <Route
              exact={true}
              path="/review-dashboard"
              store={store}
              requireAuth={Auth}
              element={<ReviewDashboard />}
            />
            <Route
              exact={true}
              path="/google/tags"
              store={store}
              requireAuth={Auth}
              element={<GoogleTagManagers />}
            />

            <Route
              exact={true}
              path="/api/review-connected"
              store={store}
              requireAuth={Auth}
              element={<POSReviews />}
            />
            <Route
              exact={true}
              path="/api/review"
              store={store}
              requireAuth={Auth}
              element={<Googlereview />}
            />
            <Route
              exact={true}
              path="/api/review/facebook"
              store={store}
              requireAuth={Auth}
              element={<FacebookReviews />}
            />
            <Route
              exact={true}
              path="/api/accounting"
              store={store}
              requireAuth={Auth}
              element={<XeroConnect />}
            />

            <Route
              exact={true}
              path="/accounting/invoices"
              store={store}
              requireAuth={Auth}
              element={<XeroInvoices />}
            />
            <Route
              exact={true}
              path="/accounting/invoices/:id"
              store={store}
              requireAuth={Auth}
              element={<XeroInvoiceDetail />}
            />
            <Route
              exact={true}
              path="/accounting/transactions"
              store={store}
              requireAuth={Auth}
              element={<XeroTransactions />}
            />
            <Route
              exact={true}
              path="/accounting/transactions/:id"
              store={store}
              requireAuth={Auth}
              element={<XeroTransactionDetail />}
            />

            <Route
              exact={true}
              path="/reviewStep"
              store={store}
              requireAuth={Auth}
              element={<Googlereviewsteps />}
            />
            <Route
              exact={true}
              path="/step"
              store={store}
              requireAuth={Auth}
              element={<step2 />}
            />
            <Route
              exact={true}
              path="/sitereview"
              store={store}
              requireAuth={Auth}
              element={<sitereview />}
            />
            <Route
              exact={true}
              path="/improvecontact"
              store={store}
              requireAuth={Auth}
              element={<improveContact />}
            />
            <Route
              exact={true}
              path="/api/reviewpage"
              store={store}
              requireAuth={Auth}
              element={<ReviewPage />}
            />
            <Route
              exact={true}
              path="/review/:id"
              store={store}
              requireAuth={Auth}
              element={<ReviewDetail />}
            />
            <Route
              exact={true}
              path="/coupon/:id"
              store={store}
              requireAuth={Auth}
              element={<CouponDetail />}
            />
            <Route
              exact={true}
              path="/crm"
              store={store}
              requireAuth={Auth}
              element={<CRM />}
            />
            <Route
              exact={true}
              path="/all-contracts"
              store={store}
              requireAuth={Auth}
              element={<AllContract />}
            />
            <Route
              exact={true}
              path="/crm/add"
              store={store}
              requireAuth={Auth}
              element={<AddEditCRM />}
            />
            <Route
              exact={true}
              path="/crm/edit/:id"
              store={store}
              requireAuth={Auth}
              element={<AddEditCRM />}
            />
            <Route
              exact={true}
              path="/crm/view/:id"
              store={store}
              requireAuth={Auth}
              element={<ViewCRM />}
            />
            <Route
              exact={true}
              path="/crm/products/:id"
              store={store}
              requireAuth={Auth}
              element={<ContractTemplateProducts />}
            />

            <Route
              exact={true}
              path="/waiver/sign/:id"
              store={store}
              requireAuth={Auth}
              element={<WaiverSignDetails />}
            />

            <Route
              exact={true}
              path="/waiver"
              store={store}
              requireAuth={Auth}
              element={<Waiver />}
            />
            <Route
              exact={true}
              path="/waiver/list"
              store={store}
              requireAuth={Auth}
              element={<WavierList />}
            />
            <Route
              exact={true}
              path="/waiver/add"
              store={store}
              requireAuth={Auth}
              element={<AddEditWaiver />}
            />

            <Route
              exact={true}
              path="/pos"
              store={store}
              requireAuth={Auth}
              element={<POS />}
            />
            <Route
              exact={true}
              path="/marketing-software"
              store={store}
              requireAuth={Auth}
              element={<MarketingSoftware />}
            />
            <Route
              exact={true}
              path="/pos/orders"
              store={store}
              requireAuth={Auth}
              element={<Orders />}
            />
            <Route
              exact={true}
              store={store}
              path="/pos/orders/orderdetail/:id"
              element={<OrderDetail />}
            />
            <Route
              exact={true}
              path="/pos/add"
              store={store}
              requireAuth={Auth}
              element={<AddEditPOS />}
            />
            <Route
              exact={true}
              path="/pos/edit/:id"
              store={store}
              requireAuth={Auth}
              element={<AddEditPOS />}
            />
            <Route
              exact={true}
              path="/sales"
              store={store}
              requireAuth={Auth}
              element={<Salesdashboard />}
            />
            <Route
              exact={true}
              path="/reseller-dashboard"
              store={store}
              requireAuth={Auth}
              element={<Resellerdashboard />}
            />

            <Route
              exact={true}
              path="/marketing/google-analytics"
              store={store}
              requireAuth={Auth}
              element={<Marketingdashboard />}
            />
            <Route
              exact={true}
              path="/marketing-analytics"
              store={store}
              requireAuth={Auth}
              element={<MarketingAnalytics />}
            />
            <Route
              exact={true}
              path="/accounting/dashboard"
              store={store}
              requireAuth={Auth}
              element={<Financialdashboard />}
            />
            <Route
              exact={true}
              path="/dynamicprice/lastminutefixeddates/add"
              requireAuth={Auth}
              element={<AddEditLastMinuteFixedDates />}
            />
            <Route
              exact={true}
              path="/dynamicprice/lastminutefixeddates/edit/:id/:copy"
              requireAuth={Auth}
              element={<AddEditLastMinuteFixedDates />}
            />
            <Route
              exact={true}
              path="/dynamicprice/lastminuteinventory/add"
              requireAuth={Auth}
              element={<AddEditLastMinuteInventory />}
            />
            <Route
              exact={true}
              path="/dynamicprice/lastminuteinventory/edit/:id/:copy"
              requireAuth={Auth}
              element={<AddEditLastMinuteInventory />}
            />
            {/* Early Bird Pricing */}
            <Route
              exact={true}
              path="/dynamicprice/earlybirdcustomdates/add"
              requireAuth={Auth}
              element={<AddEditEarlyBirdPricing />}
            />
            <Route
              exact={true}
              path="/dynamicprice/earlybirdcustomdates/edit/:id/:copy"
              requireAuth={Auth}
              element={<AddEditEarlyBirdPricing />}
            />
            <Route
              exact={true}
              path="/dynamicprice/earlybirdtimeframes/add"
              requireAuth={Auth}
              element={<AddEditEarlyBirdTimeFrames />}
            />
            <Route
              exact={true}
              path="/dynamicprice/earlybirdtimeframes/edit/:id/:copy"
              requireAuth={Auth}
              element={<AddEditEarlyBirdTimeFrames />}
            />
            <Route
              exact={true}
              path="/dynamicprice/earlybirdinventory/add"
              requireAuth={Auth}
              element={<AddEditEarlyBirdInventory />}
            />
            <Route
              exact={true}
              path="/dynamicprice/earlybirdinventory/edit/:id/:copy"
              requireAuth={Auth}
              element={<AddEditEarlyBirdInventory />}
            />
            {/* Dynamic Price */}
            <Route
              exact={true}
              path="/dynamicprice-list"
              store={store}
              requireAuth={Auth}
              element={<DynamicPrice />}
            />
            <Route
              exact={true}
              path="/dynamicprice/add"
              store={store}
              requireAuth={Auth}
              element={<AddEditPrice />}
            />
            <Route
              exact={true}
              path="/dynamicprice/:type/add"
              store={store}
              requireAuth={Auth}
              element={<AddEditPrice />}
            />
            <Route
              exact={true}
              path="/dynamicprice/:type/edit/:id/:copy"
              store={store}
              requireAuth={Auth}
              element={<AddEditPrice />}
            />
            {/* Plans */}
            <Route exact={true} path="/plans" element={<Plans />} />
            {/* Cards */}
            <Route
              exact={true}
              store={store}
              path="/card"
              element={<Cards />}
            />
            <Route
              exact={true}
              store={store}
              path="/card/add"
              element={<AddEditCards />}
            />
            <Route
              exact={true}
              store={store}
              path="/card/edit/:id"
              element={<AddEditCards />}
            />

            <Route
              exact={true}
              path="/detailcards/:id/:intervalcount/:currencyiso"
              element={<CardsDetail />}
            />
            <Route
              exact={true}
              path="/thanku"
              store={store}
              element={<Thanku />}
            />
            <Route
              exact={true}
              path="/allreview"
              store={store}
              element={<All_review />}
            />
            <Route
              exact={true}
              path="/customerdetails/:id"
              store={store}
              element={<CustomerDataDetails />}
            />

            {/*  WOrk On Wavier Module */}

            <Route
              exact={true}
              path="/waiver/template/add/:id?"
              store={store}
              requireAuth={Auth}
              element={<AddEditWavierTemp />}
            />

            <Route
              exact={true}
              path="/waiver/template/view/:id"
              store={store}
              requireAuth={Auth}
              element={<ViewWavier />}
            />
            <Route
              exact={true}
              path="/waiver/template/products/:id"
              store={store}
              requireAuth={Auth}
              element={<CWavierTemplateProducts />}
            />

            <Route
              exact={true}
              path="/submit/thanks"
              store={store}
              requireAuth={Auth}
              element={<SubmissionTanks />}
            />

            {/*  FOR EMAIL LIST ###################### */}
            <Route
              exact={true}
              store={store}
              path="/email/list"
              element={<EmailList />}
            />
            <Route
              exact={true}
              store={store}
              path="/email/templates"
              element={<EmailTemplate />}
            />
            <Route
              exact={true}
              store={store}
              path="/email/addedit/:id?"
              element={<AddEditEmailTemplate />}
            />

            {/* FOR CHAT-BOT */}
            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/chatbot"
              element={<ChatBot />}
            />

            <Route
              exact={true}
              requireAuth={Auth}
              store={store}
              path="/chatbot/callback"
              element={<GoogleDriveCallBack />}
            />

            <Route
              exact={true}
              store={store}
              path="/social/media"
              element={<SocialMediaPost />}
            />

            <Route
              exact={true}
              store={store}
              path="/social/media/addedit/:id?"
              element={<PostForm />}
            />

            <Route
              exact={true}
              store={store}
              path="/cancel/policy"
              element={<CancelReason />}
            />
            <Route
              exact={true}
              store={store}
              path="/omni-channel-setting/google/tag"
              element={<GoogleTagManager />}
            />
            <Route
              exact={true}
              store={store}
              path="/cancel/policy/add/:id?"
              element={<AddEditPolicy />}
            />
            <Route
              exact={true}
              store={store}
              path="/content/detail/:id"
              element={<ContentDetails />}
            />
            <Route
              exact={true}
              store={store}
              path="/reseller/invoice/:id?"
              element={<InvoiceDetailPage />}
            />

            {/*  ROUTES FOR COSTING TEMPLATE MODULE  */}
            <Route
              exact={true}
              path="/costing/template"
              store={store}
              requireAuth={Auth}
              element={<CostingTemplates />}
            />
            <Route
              exact={true}
              path="/costing/add"
              store={store}
              requireAuth={Auth}
              element={<AddEditCostingTemp />}
            />
            <Route
              exact={true}
              path="/costing/edit/:id"
              store={store}
              requireAuth={Auth}
              element={<AddEditCostingTemp />}
            />

            {/*  ROUTES FOR COSTING PER TOUR TEMPLATE MODULE  */}
            <Route
              exact={true}
              path={CostingTourURL.list}
              store={store}
              requireAuth={Auth}
              element={<CostingTourTemplates />}
            />
            <Route
              exact={true}
              path={CostingTourURL.add}
              store={store}
              requireAuth={Auth}
              element={<AddEditCostingTourTemp />}
            />
            <Route
              exact={true}
              path={`${CostingTourURL.edit}/:id`}
              store={store}
              requireAuth={Auth}
              element={<AddEditCostingTourTemp />}
            />
            <Route
              element={<QuickBookConnect />}
              requireAuth={Auth}
              path="/connect/quickbook"

            />

            {/*  ROUTES FOR GMAIL TEMPLATE */}
            <Route
              exact={true}
              path={GmailTempURL.list}
              store={store}
              requireAuth={Auth}
              element={<GmailTemplates />}
            />
            <Route
              exact={true}
              path={GmailTempURL.add}
              store={store}
              requireAuth={Auth}
              element={<AddEditGmailTemp />}
            />
            <Route
              exact={true}
              path={`${GmailTempURL.edit}/:id`}
              store={store}
              requireAuth={Auth}
              element={<AddEditGmailTemp />}
            />

            <Route
              exact={true}
              path={`/reviews/settings`}
              store={store}
              requireAuth={Auth}
              element={<ReviewSettings />}
            />
            <Route
              exact={true}
              path={`/wavier/settings`}
              store={store}
              requireAuth={Auth}
              element={<WaiverSettings />}
            />



            <Route
              exact={true}
              path={BannerURL.general}
              store={store}
              requireAuth={Auth}
              element={<Banners />}
            />
            <Route
              exact={true}
              path={BannerURL.product}
              store={store}
              requireAuth={Auth}
              element={<ProductBanners />}
            />
            <Route
              exact={true}
              path={BannerURL.add}
              store={store}
              requireAuth={Auth}
              element={<AddEditBanner />}
            />
            <Route
              exact={true}
              path={productBannerURL.addProduct}
              store={store}
              requireAuth={Auth}
              element={<AddEditProductBanner />}
            />
            <Route
              exact={true}
              path={`${productBannerURL.edit}/:id`}
              store={store}
              requireAuth={Auth}
              element={<AddEditProductBanner />}
            />
            <Route
              exact={true}
              path={`${BannerURL.edit}/:id`}
              store={store}
              requireAuth={Auth}
              element={<AddEditBanner />}
            />
            <Route
              exact={true}
              path={`/affiliate/request/:id`}
              store={store}
              requireAuth={Auth}
              element={<AffiliateCommission />}
            />
            <Route
              exact={true}
              path={`/affiliateInbox/:id`}
              store={store}
              requireAuth={Auth}
              element={<InboxDetail />}
            />
            <Route
              exact={true}
              path={`/affiliateInbox`}
              store={store}
              requireAuth={Auth}
              element={<InboxMessages />}
            />
            <Route
              exact={true}
              path={`/omni-channel-setting/copy`}
              store={store}
              requireAuth={Auth}
              element={<CopyofIntegration />}
            />

            <Route
              exact={true}
              path={`/current-affiliate`}
              store={store}
              requireAuth={Auth}
              element={<CurrentAffiliateData />}
            />

            <Route
              exact={true}
              path={`/default-commission`}
              store={store}
              requireAuth={Auth}
              element={<CommissionDetails />}
            />

            <Route
              exact={true}
              path={`/transactions`}
              store={store}
              requireAuth={Auth}
              element={<Transactions />}
            />
            <Route
              exact={true}
              path={`/invoice/template`}
              store={store}
              requireAuth={Auth}
              element={<Invoices />}
            />


            <Route
              exact={true}
              path="/inovoice/template/edit/:id?"
              store={store}
              requireAuth={Auth}
              element={<AddEditInvoice />}
            />
            <Route
              exact={true}
              path="/invoice/template/view/:id"
              store={store}
              requireAuth={Auth}
              element={<ViewInvoiceTemplate />}
            />
            <Route
              exact={true}
              path="/invoice/products/:id"
              store={store}
              requireAuth={Auth}
              element={<InvoiceTemplateProducts />}
            />

            <Route
              exact={true}
              path={`/report/sales`}
              store={store}
              requireAuth={Auth}
              element={< Sales />}
            />

            <Route
              exact={true}
              path={`/report/affiliates`}
              store={store}
              requireAuth={Auth}
              element={<AffiliatesReport />}
            />
            <Route
              exact={true}
              path="/invoice/temp/view"
              element={<InvoiceViewTemp />}
            />

            <Route
              exact={true}
              path={`/report/customers`}
              store={store}
              requireAuth={Auth}
              element={<AffiliateCustomers />}
            />
            <Route
              exact={true}
              path={`/invoice-commission`}
              store={store}
              requireAuth={Auth}
              element={<SupplierCommissionDetails />}
            />
            <Route
              exact={true}
              path={`/procharter`}
              store={store}
              requireAuth={Auth}
              element={<DynamicPicingProcharter />}
            />

            <Route exact={true} store={store} path="*" element={noPages} />

            <Route exact path="/" element={<Navigate to="/login" />}>
              {/* <Navigate to="/login" /> */}
            </Route>
          </Routes>
        </Router>
      </Suspense>

      <audio src="/assets/notification.mp3" id="audioFile" />

      <div
        className={`fixed top-5 z-50 right-0 bg-primary text-white py-3 px-6 rounded-lg shadow-lg transition-transform duration-500 ${show ? "translate-x-0" : "translate-x-full"
          }`}
        style={{ transform: show ? "translateX(0)" : "translateX(100%)" }}
        onClick={e => methodModel.route(RouteData)}
      >
        {NotificationMessage}
      </div>



      <div id="loader" className="loaderDiv !hidden">
        <div>
          <img
            src="/assets/img/loader.gif"
            width="auto"
            height="auto"
            alt="logo"
            className="pageLoader"
          />
        </div>
      </div>
      <ToastContainer

      />
    </>
  );
};
