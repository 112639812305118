import React, { useEffect } from 'react'
import methodModel from '../../methods/methods'
import ApiClient from '../../methods/api/apiClient';
import { useNavigate } from 'react-router-dom';
import loader from '../../methods/loader';

export default function QuickBookConnect() {
    const code = methodModel.getPrams('code');
    const Navigate = useNavigate()
    useEffect(() => {
        loader(true)
        if (code) {
            ApiClient.get(`quickbook/callback`, { code }).then(res => {
                if (res.success) {
                    Navigate('/api/accounting')
                }
                loader(false)
            })
        } else {
            Navigate('/api/accounting')
            loader(false)
        }
    }, [code])
    return (
        <div>QuickBookConnect</div>
    )
}
